import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { Typography, Paper } from '@material-ui/core'

const DATA = [
  {
    name: 'Yuval Harari',
  },
  {
    name: 'Skye Humpries',
  },
  {
    name: 'Ramona Steffeld',
  },
  {
    name: 'Nelson Quest',
  },
  {
    name: 'mpj',
  },
  {
    name: 'Public_Speaking_MAN',
  },
  {
    name: 'Gary Vaynerchuk',
  },
  {
    name: 'Thomas Blacharz',
  },
]

const People = ({ data }) => (
  <>
    <Paper style={{ padding: '20px' }}>
      {data.map(({ name }) => (
        <li>{name}</li>
      ))}
    </Paper>
  </>
)

export default props => {
  const {
    data: {
      site: {
        siteMetadata: { title },
      },
    },
    location,
  } = props
  return (
    <Layout location={location} title={title}>
      <Typography variant={'h3'}>PEOPLE I LEARN FROM</Typography>
      <People data={DATA} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`
